<template>
  <div class="flex">
    <div class="side_left">
      <!-- 游客消息列表 -->
      <div class="left_one"
           v-loading="tableLoading">
        <div class="list_head">
          <div class="list_head_cell list_head_cell_active">用户列表</div>
        </div>
        <div class="tourist_news_list overflow_y">
          <el-affix :offset="45">
            <div class="retrieval">
              <el-input v-model="searchVal1"
                        placeholder="请输入姓名"></el-input>
            </div>
          </el-affix>
          <div @click="privateChat(item.userid, item.name)"
               class="tourist bb pl10 pr10"
               v-for="(item, index) in customerList.filter(
              (data) =>
                !searchVal1 ||
                data.name.toLowerCase().includes(searchVal1.toLowerCase())
            )"
               :key="index">
            <div class="tourist_news_cell">
              <div class="tourist_avatar_cell">
                <img class="tourist_avatar_img"
                     src="@/assets/img/icon_user.png"
                     alt="" />
              </div>
              <div class="flex fd_c jc_b">
                <div class="flex at_c">
                  <div class="fz14">{{ item.name }}</div>
                </div>
                <!-- <div class="tourist_time">{{ item.time }}</div> -->
              </div>
              <!-- <div v-if="item.is_new_message" class="tourist_news_tips"></div> -->
            </div>
          </div>
          <div class="is_end">已全部加载</div>
        </div>
      </div>
    </div>
    <!-- 聊天 -->
    <div class="private_chat flex at_c fd_c"
         v-if="!chatBoxStatus">
      <img class="none_bg"
           src="@/assets/img/none_bg.png"
           alt="" />
      <div class="info">暂无相关应用，请点击右侧应用进行群发消息！</div>
    </div>
    <div class="private_chat"
         v-loading="messageListLoading"
         v-if="chatBoxStatus">
      <div class="private_chat_head">
        <div>
          <span>{{ currentTalkMember.name }} </span>
        </div>
        <!-- <div class="flex at_c">
          <img
            class="icon_operation"
            src="@/assets/img/icon_ban_say.png"
            alt=""
            title="屏蔽用户观看时长"
          />
          <img
            class="icon_operation"
            src="@/assets/img/icon_shield.png"
            alt=""
            title="禁言该用户"
          />
          <img
            class="icon_operation"
            src="@/assets/img/icon_seal_ip.png"
            alt=""
            title="封禁该用户"
          />
        </div> -->
      </div>
      <div class="private_chat_body">
        <div class="private_chat_left"
             :class="{ max_width: !chatBoxRightStatus }">
          <div class="private_chat_list overflow_y"
               id="privateChat">
            <div v-for="(item, index) in messageList"
                 :key="index">
              <!-- 讲师说话 -->
              <div v-if="item.direction == 1"
                   class="flex fd_c at_e">
                <div class="flex at_c fz12"
                     style="color: #858fa2">
                  <div class="mr6">{{ item.name }}</div>
                  <div>{{ item.time }}</div>
                </div>
                <div class="cori_chat_box_list_cell_content"
                     style="background-color: #347eff; color: #fff"
                     v-html="item.content"></div>
              </div>
              <!-- 来访者说话 -->
              <div v-if="item.direction == 2"
                   class="cori_chat_box_list_cell">
                <img class="cori_chat_box_list_cell_avatar"
                     :src="item.avatar" />
                <div class="cori_chat_box_list_cell_info">
                  <div class="flex at_c fz12"
                       style="color: #858fa2">
                    <div class="mr6">{{ item.name }}</div>
                    <div>{{ item.time }}</div>
                  </div>
                  <div class="cori_chat_box_list_cell_content"
                       v-html="item.content"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="private_chat_bottom">
            <div class="private_chat_bottom_more pr">
              <img @click="changeEmojiStatus('private')"
                   class="private_chat_bottom_more_icon"
                   src="@/assets/img/icon_emoji.png"
                   alt="" />
            </div>
            <div class="private_chat_input_body">
              <textarea v-model="textarea"
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        @keyup.enter="chatSend"></textarea>
              <div class="flex at_c jc_e">
                <div @click="chatSend"
                     class="chat_btn">发送</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="private_chat_right" v-if="chatBoxRightStatus">
          <div class="private_chat_right_title">
            <img src="@/assets/img/icon_history.png" alt="" />
            历史轨迹
          </div>
          <div class="fz14">
            <div class="bb p10 border-b">
              首次访问 {{ memberInfo.first_time }}
            </div>
            <div class="bb p10 border-b">
              本次访问 {{ memberInfo.last_time }}
            </div>
            <div class="bb p10 border-b">
              本月访问次数 {{ memberInfo.visit_count }}次
            </div>
          </div>
          <div class="private_chat_right_title">
            <img src="@/assets/img/icon_infor.png" alt="" />
            客户信息
          </div>
          <div class="customer_info overflow_y">
            <div class="customer_cell">
              <div>昵称</div>
              <div>{{ memberInfo.nickname }}</div>
            </div>
            <div class="customer_cell">
              <div>IP</div>
              <div>{{ memberInfo.ip }}</div>
            </div>
            <div class="customer_cell">
              <div>着陆页</div>
              <div>{{ memberInfo.url }}</div>
            </div>
            <div class="customer_cell">
              <div>省市</div>
              <div>
                {{ memberInfo.province }}{{ memberInfo.city
                }}{{ memberInfo.county }}
              </div>
            </div>
            <div class="customer_cell">
              <div>运营商</div>
              <div>{{ memberInfo.lsp }}</div>
            </div>
            <div class="customer_cell">
              <div>关键词</div>
              <div>{{ memberInfo.keywords }}</div>
            </div>
            <div class="customer_cell">
              <div>用户组</div>
              <div>{{ memberInfo.type }}</div>
            </div>
            <div class="customer_cell">
              <div>观看视频</div>
              <div>{{ memberInfo.video_status == 1 ? "正常" : "封禁" }}</div>
            </div>
            <div class="customer_cell">
              <div>发言</div>
              <div>{{ memberInfo.chat_status == 1 ? "正常" : "封禁" }}</div>
            </div>
            <div class="customer_cell">
              <div>归属</div>
              <div>{{ memberInfo.service_name }}</div>
            </div>
            <div class="cori_divider cori_divider_small">
              <div>实盘数据</div>
            </div>
            <div class="customer_cell">
              <div>姓名</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
            <div class="customer_cell">
              <div>休眠天数</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
            <div class="customer_cell">
              <div>期末权益</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
            <div class="customer_cell">
              <div>开户时间</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
            <div class="customer_cell">
              <div>出金</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="side_right">
      <!-- 应用列表 -->
      <div class="right_one"
           v-loading="appListLoading">
        <div class="plate_title">
          <div class="plate_title_name">应用列表</div>
        </div>
        <div class="bb p20 flex fw_w overflow_y">
          <div @click="wechatChat(item.agent_id, item.name)"
               class="app_cell"
               v-for="(item, index) in appList"
               :key="index">
            <img :src="item.image"
                 alt="" />
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
      <!-- 小程序 -->
      <div class="right_two">
        <div class="plate_title">
          <div class="plate_title_name">小程序</div>
          <el-button v-if="is_service_admin==1"
                     @click="showShareImg"
                     size="small"
                     type="primary">弹出</el-button>
        </div>
        <div class="applet_body">
          <img src="@/assets/img/applet.png"
               alt="" />
          <div>扫一扫，进入微信小程序</div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible"
               title="上传图片"
               width="50%">
      <div class="dialog_body">
        <img style="height: 100%;" :src="imgBg"
             alt="" />
        <img class="qrImg"
             v-if="qrImg"
             :src="qrImg"
             alt="" />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <div>
            <el-upload class="upload-demo"
                       action="https://wss.jiancewangluo.com/teacher/common/upload"
                       :on-success="handleAvatarSuccess"
                       :on-preview="handlePreview"
                       :on-remove="handleRemove"
                       :limit="1"
                       :on-exceed="handleExceed"
                       :file-list="fileList">
              <el-button type="primary">上传二维码</el-button>
              <template #tip>
                <div class="el-upload__tip"></div>
              </template>
            </el-upload>
          </div>
          <div>
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary"
                       @click="createPoster">生成图片</el-button>
          </div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "../api/enterpriseWechat";
import Emoji from "../components/emoji.vue";
import emojiList from "../utils/emoji";
export default {
  components: {
    Emoji,
  },
  data() {
    return {
      userInfo: {}, // 研究员信息
      userName: "", //  当前用户名称
      userId: null, // 当前用户id
      page: 1,
      customerList: [], //客户列表
      isEnd: false,
      currentTalkMember: {
        userid: null,
        name: "",
        type: null, //1用户2应用
      }, //当前聊天对象用户信息
      messageList: [], //当前聊天消息列表
      messageListLoading: false, // 当前聊天消息列表加载状态
      textarea: "", // 私聊待发送内容
      memberInfo: {}, // 私聊人员相关信息

      tableLoading: false, // 多人群发数据表加载状态
      searchVal1: "", // 会员列表检索
      chatBoxStatus: false,
      chatBoxRightStatus: true,

      appList: [],
      appListLoading: false,

      dialogVisible: false,
      imgBg: "",
      qrImg: "",
      is_service_admin: localStorage.getItem("is_service_admin"),
    };
  },
  created() {},
  mounted() {
    window.onmessageSocket = this.onmessage
    this.getUserList();
    this.getAppList();
  },
  methods: {
    onmessage(e) {

    },
    cancel() {
      this.dialogVisible = false;
      this.qrImg = "";
      this.fileList = [];
    },
    async createPoster() {
      var qrImg = this.qrImg;
      if (!qrImg) {
        this.$message.error("请先上传二维码图片");
        return false;
      }
      await Service.createWorkWeixinPoster({
        qr_path: qrImg,
      });
      this.$message.success("操作成功");
      this.showShareImg();
    },
    handleAvatarSuccess(res, file) {
      this.qrImg = res.data.fullurl;
    },
    handlePreview(file) {
      // console.log(file);
    },
    handleRemove(file, fileList) {
      this.qrImg = "";
      this.fileList = [];
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    chatInit() {
      this.textarea = "";
    },
    // 获取客户列表
    async getUserList() {
      this.tableLoading = true;
      const res = await Service.getUserList();
      // console.log("获取客户列表", res);
      this.customerList = res.data;
      this.tableLoading = false;
    },
    //获取应用列表
    async getAppList() {
      this.appListLoading = true;
      const res = await Service.getAppList();
      // console.log("获取应用列表", res);
      this.appList = res.data;
      this.appListLoading = false;
    },
    //聊天滚动
    scoll1() {
      $("#privateChat").animate(
        {
          scrollTop: "99999px",
        },
        1200
      );
    },
    //企业微信用户消息记录
    async privateChat(userid, name) {
      this.chatInit();
      this.messageListLoading = true;
      this.chatBoxStatus = true;
      this.chatBoxRightStatus = true;
      this.messageList = [];
      this.currentTalkMember = {
        userid,
        name,
        type: 1,
      };
      const res = await Service.getUserChatList({
        userid,
        page: 1,
      });
      // console.log("企业微信用户消息记录", res);
      var resData = res.data.data,
        messageList = [];
      for (const item of resData) {
        messageList.unshift({
          name: "",
          avatar: "",
          time: item.createtime,
          content: item.content,
          direction: 1,
        });
      }
      this.messageList = messageList;
      this.messageListLoading = false;
      this.scoll1();
    },
    //企业微信应用消息记录
    async wechatChat(agent_id, name) {
      this.chatInit();
      this.messageListLoading = true;
      this.chatBoxStatus = true;
      this.chatBoxRightStatus = true;
      this.messageList = [];
      this.currentTalkMember = {
        userid: agent_id,
        name,
        type: 2,
      };
      const res = await Service.getAppChatList({
        agent_id,
        page: 1,
      });
      // console.log("企业微信应用消息记录", res);
      var resData = res.data.data,
        messageList = [];
      for (const item of resData) {
        messageList.unshift({
          name: "",
          avatar: "",
          time: item.createtime,
          content: item.content,
          direction: 1,
        });
      }
      this.messageList = messageList;
      this.messageListLoading = false;
      this.scoll1();
    },
    //聊天发送
    async chatSend() {
      if (!this.textarea) {
        this.$message.error("不能发送空消息");
        return false;
      }
      if (this.currentTalkMember.type == 1) {
        //给用户发送消息
        await Service.sendUserMessage({
          userid: this.currentTalkMember.userid,
          content: this.textarea,
        });
      } else {
        //给应用发送消息
        await Service.sendAppMessage({
          agent_id: this.currentTalkMember.userid,
          content: this.textarea,
        });
      }

      this.messageList.push({
        name: "",
        avatar: "",
        time: this.getSendTime(),
        content: this.textarea,
        direction: 1,
      });
      this.textarea = "";
      this.scoll1();
    },
    //显示分享图片
    async showShareImg() {
      this.dialogVisible = true;
      const res = await Service.getWorkWeixinPoster();
      // console.log("分享图片", res);
      this.imgBg = res.data;
    },
    getSendTime() {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date = year + "-" + month + "-" + date;
      var cori_time = hour + ":" + min + ":" + sec;
      this.currentDate = cori_date;
      this.currentTime = cori_time;

      return cori_date + " " + cori_time;
    },
  },
};
</script>

<style scoped>
.side_left {
  /* width: 18%; */
  width: 334px;
  height: 100vh;
  border-right: 1px solid #ebeced;
  box-sizing: border-box;
}

.private_chat {
  /* width: 58%; */
  width: calc(100% - 334px - 350px);
  height: 100vh;
  border-right: 1px solid #ebeced;
}

.private_chat .info {
  color: #666;
  text-align: center;
  font-size: 16px;
}

.none_bg {
  width: 50%;
  margin-bottom: 20px;
}

.side_right {
  width: 350px;
  height: 100vh;
}

.right_one {
  height: calc(100% - 360px);
}

.right_two {
  height: 360px;
}

.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  /* padding: 13.5px 0; */
  height: 45px;
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.left_one {
  /* height: 580px; */
  height: calc(100% - 50px);
}

.left_two {
  /* height: calc(100% - 580px); */
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: #626a78;
  line-height: 20px;
  background: #f7f8fc;
  box-sizing: border-box;
  border-top: 1px solid #e7e9ef;
}

.list_head {
  display: flex;
  background-color: #f8fcff;
  border-bottom: 1px solid #ebeced;
}

.list_head_cell {
  flex: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  padding: 11px 0;
  font-size: 16px;
  line-height: 22px;
}

.list_head_cell_active {
  position: relative;
  color: #4571e7;
}

.list_head_cell_active::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  border: 1px solid #0092fd;
}

.tourist_news_list {
  height: 100%;
}

.retrieval {
  height: 45px;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeced;
  background-color: #fff;
}

.overflow_y {
  overflow-y: auto;
}

.overflow_y::-webkit-scrollbar {
  display: block;
  width: 4px;
}

.overflow_y::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #aaa;
  background: rgba(0, 0, 0, 0.2);
}

.overflow_y::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #aaa;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.tourist_news_cell {
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeced;
  cursor: pointer;
  position: relative;
}

.tourist_avatar_cell {
  width: 19px;
  height: 21px;
  margin-right: 6px;
}

.tourist_avatar_img {
  width: 100%;
  height: 100%;
}

.tourist_time {
  color: #8691a3;
  font-size: 12px;
  line-height: 12px;
}

.tourist {
  /* background-image: url("../assets/img/tourist_bg.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.tourist_news_tips {
  position: absolute;
  right: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ff3f30;
}

.cori_divider > div {
  font-size: 12px;
  font-weight: 400;
  color: #a3adbf;
  position: relative;
  text-align: center;
  margin: 10px 0;
}

.cori_divider > div::before {
  content: "";
  display: block;
  width: 30%;
  position: absolute;
  left: 45px;
  top: 10px;
  height: 1px;
  background: linear-gradient(90deg, #ffffff 0%, #e6e9f0 49%, #ffffff 100%);
}

.cori_divider > div::after {
  content: "";
  display: block;
  width: 30%;
  position: absolute;
  right: 45px;
  top: 10px;
  height: 1px;
  background: linear-gradient(90deg, #ffffff 0%, #e6e9f0 49%, #ffffff 100%);
}

.chat_btn {
  font-size: 12px;
  line-height: 12px;
  background-color: #5188f4;
  color: #fff;
  width: 60px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}

.private_chat_head {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 15px;
  background-color: #f8fcff;
  border-bottom: 1px solid #ebeced;
}

.private_chat_head > div > span:first-child {
  font-size: 16px;
  font-weight: 400;
  color: #3b5496;
  line-height: 22px;
  margin-right: 38px;
}

.private_chat_head > div > span:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #94a3c3;
  line-height: 20px;
}

.icon_operation {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.private_chat_body {
  display: flex;
  height: calc(100% - 45px);
}

.private_chat_left {
  /* width: calc(100% - 250px); */
  width: 100%;
}

.max_width {
  width: 100% !important;
}

.private_chat_right {
  width: 250px;
  height: 100%;
  box-sizing: border-box;
  border-left: 1px solid #ebeced;
  background-color: #f7f8fc;
}

.private_chat_list {
  box-sizing: border-box;
  padding: 20px;
  height: calc(100% - 190px);
}

.private_chat_bottom {
  width: 100%;
  height: 190px;
  background-color: #f7f8fa;
}

.private_chat_bottom_more {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
}

.private_chat_bottom_more_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.private_chat_input_body {
  height: calc(100% - 40px);
  box-sizing: border-box;
  padding: 0 10px;
}

.private_chat_input_body textarea {
  width: 100%;
  box-sizing: border-box;
  height: calc(100% - 40px);
  border: 1px solid #cccccc;
  resize: none;
  outline: none;
  padding: 10px;
}

.private_chat_right_title {
  height: 45px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeced;
  box-sizing: border-box;
  padding: 0 10px;
  line-height: 14px;
  font-size: 14px;
}

.private_chat_right_title img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.border-b {
  border-bottom: 1px solid #ebeced;
}

.customer_info {
  box-sizing: border-box;
  padding: 10px;
  height: calc(100% - 210px);
}

.customer_cell {
  display: flex;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 14px;
  color: #94a3c3;
}

.customer_cell > div:first-child {
  width: 60px;
}

.customer_cell > div:last-child {
  width: calc(100% - 60px);
}

.cori_divider_small > div {
  color: #5288f5;
}

.cori_divider_small > div::before {
  width: 30%;
  left: 15px;
}

.cori_divider_small > div::after {
  width: 30%;
  right: 15px;
}

.cori_chat_box_list_cell {
  display: flex;
}

.cori_chat_box_list_cell_avatar {
  width: 32px;
  height: 32px;
  margin-right: 6px;
}

.cori_chat_box_list_cell_info {
  width: calc(100% - 38px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mr6 {
  margin-right: 6px;
}

.cori_chat_box_list_cell_content {
  margin: 6px 0 20px;
  font-size: 14px;
  max-width: 65%;
  background-color: #f7f8fc;
  box-sizing: border-box;
  padding: 6px 10px;
  border-radius: 5px;
}

.is_end {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 12px 0;
  font-size: 12px;
  color: #ccc;
}

.app_cell {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.app_cell img {
  width: 80%;
  margin-bottom: 10px;
}

.app_cell div {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #111111;
}

.applet_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666;
  text-align: center;
  font-size: 16px;
}

.applet_body img {
  width: 60%;
  margin: 20px 0;
}

.dialog_body {
  height: 50vh;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  position: relative;
}

.qrImg {
  position: absolute;
  width: 150px;
  top: 252px;
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/deep/.upload-demo {
  display: flex;
}
</style>
