import request from "../utils/request";

//获取初始化数据
export const initInfo = (query) => {
  return request({
    url: "/service/chat/getInitInfo",
    method: "POST",
    data: query,
  });
};
//设置客服状态
export const setServiceStatus = (query) => {
  return request({
    url: "/service/user/setServiceStatus",
    method: "POST",
    data: query,
  });
};
//获取客服信息
export const getServiceIndex = (query) => {
  return request({
    url: "/service/user/index",
    method: "POST",
    data: query,
  });
};
//客服socket绑定用户
export const bindUser = (query) => {
  return request({
    url: "/service/chat/bindUser",
    method: "POST",
    data: query,
  });
};
//获取一对一聊天记录
export const getChatList = (query) => {
  return request({
    url: "/service/chat/getChatList",
    method: "POST",
    data: query,
  });
};
//获取公屏聊天历史消息
export const roomChatList = (query) => {
  return request({
    url: "/service/chat/getRoomChatList",
    method: "POST",
    data: query,
  })
}
//获取手机号列表
export const phoneList = (query) => {
  return request({
    url: "/service/info/getPhoneList",
    method: "POST",
    data: query,
  })
}
//手机号拨打结果
export const phoneStatus = (query) => {
  return request({
    url: "/service/info/phoneStatus",
    method: "POST",
    data: query,
  })
}
//获取课表
export const roomConfigInfo = (query) => {
  return request({
    url: "/service/manage/getRoomConfig",
    method: "POST",
    data: query,
  })
}
//配置课表
export const roomConfigSet = (query) => {
  return request({
    url: "/service/manage/setRoomConfig",
    method: "POST",
    data: query,
  })
}
//获取基础信息
export const baseInfo = (query) => {
  return request({
    url: "/service/info/getBaseInfo",
    method: "POST",
    data: query,
  })
}
//获取会员游客信息
export const userInfo = (query) => {
  return request({
    url: "/service/info/getUserInfo",
    method: "POST",
    data: query,
  })
}
//修改聊天审核按钮状态
export const changeChatStatus = (query) => {
  return request({
    url: "/service/chat/changeChatStatus",
    method: "POST",
    data: query,
  })
}
//审核公屏发言
export const applyChat = (query) => {
  return request({
    url: "/service/chat/applyChat",
    method: "POST",
    data: query,
  })
}
//删除公屏发言
export const delChat = (query) => {
  return request({
    url: "/service/chat/delChat",
    method: "POST",
    data: query,
  })
}
//封禁IP
export const banIp = (query) => {
  return request({
    url: "/service/chat/banIp",
    method: "POST",
    data: query,
  })
}
//解禁IP
export const openIp = (query) => {
  return request({
    url: "/service/chat/openIp",
    method: "POST",
    data: query,
  })
}
//修改用户视频状态
export const changeVideoStatus = (query) => {
  return request({
    url: "/service/chat/changeVideoStatus",
    method: "POST",
    data: query,
  })
}
//修改用户发言状态
export const changeUserChatStatus = (query) => {
  return request({
    url: "/service/chat/changeUserChatStatus",
    method: "POST",
    data: query,
  })
}
//配置广播
export const setBroadcast = (query) => {
  return request({
    url: "/service/chat/setBroadcast",
    method: "POST",
    data: query,
  })
}
//获取假人
export const getDummyList = (query) => {
  return request({
    url: "/service/info/getUserList",
    method: "POST",
    data: query,
  })
}
// 获取我的海报
export const getUserPoster = (query) => {
  return request({
    url: "/service/user/getUserPoster",
    method: "POST",
    data: query,
  })
}
// 配置快捷回复
export const setQuickReply = (query) => {
  return request({
    url: "/service/chat/setQuickReply",
    method: "POST",
    data: query,
  })
}
// 获取socket链接
export const getSocketUrl = (query) => {
  return request({
    url: "/api/common/getSocketUrl",
    method: "POST",
    data: query,
  })
}
