<template>
  <div>
    <pageTitle :title="title"></pageTitle>
    <div class="bb p30">
      <div class="cori_form"
         v-loading="loading">
      <div>
        <div>停播图</div>
        <el-upload class="avatar-uploader"
                   action="https://wss.jiancewangluo.com/service/common/upload"
                   :show-file-list="false"
                   :on-success="handleAvatarSuccess">
          <div class="avatar-uploader_div"
               v-if="stop_image">
            <img :src="stop_image"
                 class="avatar" />
            <div class="zhezhao">
              <el-icon @click.stop="handleRemove"
                       :size="24">
                <Delete color="#ffffff" />
              </el-icon>
            </div>
          </div>
          <el-icon v-else
                   class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </div>
      <el-table border
                :data="tableData"
                style="width: 100%">
        <el-table-column align="center"
                         label="时间范围"
                         width="400">
          <template #default="scope">
            <div class="demo-time-range">
              <el-time-select v-model="scope.row.start_time"
                              :max-time="scope.row.end_time"
                              placeholder="请选择开始时间"
                              start="08:30"
                              step="00:10"
                              end="23:00">
              </el-time-select>
              -
              <el-time-select v-model="scope.row.end_time"
                              :min-time="scope.row.start_time"
                              placeholder="请选择结束时间"
                              start="08:30"
                              step="00:10"
                              end="23:00">
              </el-time-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center"
                         label="内容"
                         width="300">
          <template #default="scope">
            <el-input v-model="scope.row.title"
                      placeholder="请输入内容"></el-input>
          </template>
        </el-table-column>
        <el-table-column align="center"
                         label="分析师"
                         width="200">
          <template #default="scope">
            <el-select v-model="scope.row.teacher"
                       class="m-2"
                       placeholder="请选择分析师">
              <el-option v-for="item in analystList"
                         :key="item.id"
                         :label="item.nickname"
                         :value="item.id">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column align="center"
                         label="操作"
                         width="100">
          <template #default="scope">
            <el-button size="small"
                       type="danger"
                       @click="handleDelete(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="mt20 ml20 flex">
      <el-button @click="handleAdd"
                 type="primary">追 加</el-button>
      <el-button @click="submit"
                 type="primary">保 存</el-button>
    </div>
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
import { Delete, Plus } from "@element-plus/icons-vue";
import pageTitle from "../common/pageTitle.vue";
export default {
  components: {
    Plus,
    Delete,
    pageTitle
  },
  data() {
    return {
      title: "课表编辑",
      analystList: [], // 老师列表
      tableData: [
        {
          start_time: "",
          end_time: "",
          title: "",
          teacher: "",
        },
      ],
      room_id: 1,
      loading: false,
      stop_image: "", // 停播图
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage
    this.getTeacherList();
    this.getRoomConfig();
  },
  methods: {
    onmessage(e) {

    },
    handleRemove() {
      this.stop_image = "";
    },
    handleAvatarSuccess(res, file) {
      this.stop_image = res.data.fullurl;
    },
    // 获取老师列表
    async getTeacherList() {
      const res = await Service.baseInfo();
      // console.log("res", res);
      var analystList = res.data.teacherList;
      analystList.unshift({
        id: 0,
        nickname: "无",
      });
      this.analystList = analystList;
    },
    // 获取课表
    async getRoomConfig() {
      this.loading = true;
      const res = await Service.roomConfigInfo({
        room_id: this.room_id,
      });
      var tableData = res.data.room_setting;
      // console.log("tableData", tableData);
      for (const item of tableData) {
        item.teacher = Number(item.teacher);
      }
      this.tableData = tableData;
      this.stop_image = res.data.stop_image;
      this.loading = false;
    },
    // 删除
    handleDelete(index) {
      this.tableData.splice(index, 1);
    },
    // 增加
    handleAdd() {
      this.tableData.push({
        start_time: "",
        end_time: "",
        title: "",
        teacher: "",
      });
    },
    // 保存
    async submit() {
      const res = await Service.roomConfigSet({
        room_id: this.room_id,
        stop_image: this.stop_image,
        room_setting: this.tableData,
      });
      this.$message.success(res.msg);
      // console.log("submit", res);
    },
  },
};
</script>

<style scoped>
.cori_form {
  width: 1000px;
}
.demo-time-range .el-select {
  width: 170px;
}

.avatar-uploader_div {
  width: 200px;
  height: 200px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  text-align: center;
}
</style>