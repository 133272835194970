<template>
  <div class="flex"
       @click="emojiStatusHide">
    <div class="side_left">
      <!-- 游客消息列表 -->
      <div class="left_one">
        <div class="list_head">
          <div @click="changeTouristNewsStatus(1)"
               :class="{'list_head_cell_active':touristNewsStatus==1}"
               class="list_head_cell">
            <img v-if="touristNewsStatus==1"
                 src="@/assets/img/online_checked.png"
                 alt="">
            <img v-if="touristNewsStatus==2"
                 src="@/assets/img/online_unchecked.png"
                 alt="">
          </div>
          <!-- <div @click="changeTouristNewsStatus(2)"
               :class="{'list_head_cell_active':touristNewsStatus==2}"
               class="list_head_cell">
            <img v-if="touristNewsStatus==1"
                 src="@/assets/img/offline_unchecked.png"
                 alt="">
            <img v-if="touristNewsStatus==2"
                 src="@/assets/img/offline_checked.png"
                 alt="">
          </div> -->
        </div>
        <div class="tourist_news_list overflow_y"
             v-loading="memberListLoading">
          <div class="flex at_c jc_c h100"
               v-if="!touristNewsList.length && !user_list.length">
            <img class="icon_nav_hq"
                 src="@/assets/img/icon_nav_hq.png"
                 alt="">
          </div>

          <div @click="privateChat(item.type,item.id,item.nickname,1)"
               class="tourist bb pl10 pr10"
               v-for="(item,index) in touristNewsList"
               :key="index">
            <div class="tourist_news_cell">
              <div class="tourist_avatar_cell">
                <img class="tourist_avatar_img"
                     :src="item.avatar"
                     alt="">
              </div>
              <div class="h40 flex fd_c jc_b">
                <div class="flex at_c">
                  <div class="tourist_identity color_huiyuan"
                       v-if="item.type==1">会员</div>
                  <div class="tourist_identity color_youke"
                       v-if="item.type==2">{{item.user_type}}</div>
                  <div class="tourist_identity color_jiangshi"
                       v-if="item.type==3">讲师</div>
                  <div class="tourist_identity color_guanli"
                       v-if="item.type==4">管理</div>
                  <div class="tourist_identity color_kefu"
                       v-if="item.type==5">客服</div>
                  <div class="fz14">{{item.nickname}}</div>
                  <img v-if="item.is_new"
                       class="icon_new"
                       src="@/assets/img/icon_new.png"
                       alt="">
                </div>
                <div class="tourist_time">{{item.time}}</div>
              </div>
              <div v-if="item.is_new_message"
                   class="tourist_news_tips"></div>
            </div>
          </div>

          <div @click="privateChat(item.type,item.id,item.nickname,2)"
               class="bb pl10 pr10"
               v-for="(item,index) in user_list"
               :key="index">
            <div class="tourist_news_cell">
              <div class="tourist_avatar_cell">
                <img class="tourist_avatar_img"
                     :src="item.avatar"
                     alt="">
              </div>
              <div class="h40 flex fd_c jc_b">
                <div class="flex at_c">
                  <div class="tourist_identity color_huiyuan"
                       v-if="item.type==1">会员</div>
                  <div class="tourist_identity color_youke"
                       v-if="item.type==2">{{item.user_type}}</div>
                  <div class="tourist_identity color_jiangshi"
                       v-if="item.type==3">讲师</div>
                  <div class="tourist_identity color_guanli"
                       v-if="item.type==4">管理</div>
                  <div class="tourist_identity color_kefu"
                       v-if="item.type==5">客服</div>
                  <div class="fz14">{{item.nickname}}</div>
                  <img v-if="item.is_new"
                       class="icon_new"
                       src="@/assets/img/icon_new.png"
                       alt="">
                </div>
                <div class="tourist_time">{{item.time}}</div>
              </div>
              <div v-if="item.is_new_message"
                   class="tourist_news_tips"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 未拨打手机号列表 -->
      <!-- <div class="left_two">
        <div class="list_head">
          <div class="list_head_cell list_head_cell_active flex at_c">
            <div class="flex at_c jc_c"
                 style="flex: 1">
              <img class="offline_un"
                   src="@/assets/img/offline_un.png"
                   alt="">
              网销未拨打
            </div>
            <div class="flex at_c jc_c"
                 style="flex: 1">
              <img class="offline_un"
                   src="@/assets/img/offline_un.png"
                   alt="">
              电销未拨打
            </div>
          </div>
        </div>
        <div class="flex div_phone_list">
          <div class="phone_list bb pl10 pr10 overflow_y"
               v-loading="phoneListLoading1">
            <div class="phone_cell"
                 v-for="(item,index) in phoneList1"
                 :key="index"
                 @click="showBox(item.name,item.phone,item.id,1)">
              <div class="fz14">{{item.name || '无'}}</div>
              <div class="phone_time">{{item.create_time}}</div>
              <div class="phone_type">{{item.source_title}}</div>
              <div class="flex at_c">
                <div class="fz14">{{item.phone}}</div>
                <img class="phone_copy"
                     src="@/assets/img/icon_copy.png"
                     alt="">
              </div>
            </div>
          </div>
          <div class="phone_list bb pl10 pr10 overflow_y"
               v-loading="phoneListLoading2">
            <div class="phone_cell"
                 v-for="(item,index) in phoneList2"
                 :key="index"
                 @click="showBox(item.name,item.phone,item.id,2)">

              <div class="fz14">{{item.name || '无'}}</div>
              <div class="phone_time">{{item.create_time}}</div>
              <div class="phone_type">{{item.source_title}}</div>
              <div class="flex at_c">
                <div class="fz14">{{item.phone}}</div>
                <img class="phone_copy"
                     src="@/assets/img/icon_copy.png"
                     alt="">
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 视频直播 -->
    <div v-show="pageType==1"
         class="video_center">
      <!-- <video v-if="live_status==1"
             muted
             autoplay
             id="hls-video"
             width="1024"
             height="576"
             class="video-js vjs-default-skin"
             playsinline
             webkit-playsinline
             controls
             preload="auto"
             x-webkit-airplay="true"
             x5-video-player-fullscreen="true"
             x5-video-player-typ="h5">
        <source :src="videoUrl"
                type="application/x-mpegURL">
      </video>
      <template v-else>
        <img class="w100"
             :src="stop_image"
             alt="">
        <div id="hls-video"></div>
      </template> -->

    </div>
    <!-- 客服1v1聊天 -->
    <div v-show="pageType==2"
         class="private_chat"
         v-loading="messageListLoading">
      <div class="private_chat_head">
        <div>
          <span>{{currentTalkMember.nickname}}</span>
          <span class="fz14 ml20"
                v-if="memberInfo.keywords"
                style="color: #96A5C4;"> 关键词：{{memberInfo.keywords}}</span>
        </div>
        <!-- <div class="flex at_c">
          <img @click="ban('video','',currentTalkMember.id,currentTalkMember.type)"
               class="icon_operation"
               src="@/assets/img/icon_ban_say.png"
               alt=""
               title="屏蔽用户观看时长">
          <img @click="ban('talk','',currentTalkMember.id,currentTalkMember.type)"
               class="icon_operation"
               src="@/assets/img/icon_shield.png"
               alt=""
               title="禁言该用户">
          <img @click="ban('ip',memberInfo.ip)"
               class="icon_operation"
               src="@/assets/img/icon_seal_ip.png"
               alt=""
               title="封禁该用户">
        </div> -->
      </div>
      <div class="private_chat_body">
        <div class="private_chat_left">
          <div class="private_chat_list overflow_y"
               id="privateChat">
            <div v-for="(item,index) in messageList"
                 :key="index">
              <!-- 客服说话 -->
              <div v-if="item.direction==1"
                   class="flex fd_c at_e">
                <div class="flex at_c fz12"
                     style="color: #858FA2;">
                  <div class="mr6">{{item.name}}</div>
                  <div>{{item.time}}</div>
                </div>
                <div class="cori_chat_box_list_cell_content"
                     style="background-color: #347EFF;color: #fff;"
                     v-html="item.content"></div>
              </div>
              <!-- 来访者说话 -->
              <div v-if="item.direction==2"
                   class="cori_chat_box_list_cell">
                <img class="cori_chat_box_list_cell_avatar"
                     :src="item.avatar">
                <div class="cori_chat_box_list_cell_info">
                  <div class="flex at_c fz12"
                       style="color: #858FA2;">
                    <div class="mr6">{{item.name}}</div>
                    <div>{{item.time}}</div>
                  </div>
                  <div class="cori_chat_box_list_cell_content"
                       v-html="item.content"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="private_chat_bottom">
            <div class="private_chat_bottom_more pr">
              <emoji :id="emojiId"
                     v-if="privateEmojiStatus"
                     @changeTextarea="changeTextarea"></emoji>
              <img @click.stop="changeEmojiStatus('private')"
                   class="private_chat_bottom_more_icon"
                   src="@/assets/img/icon_emoji.png"
                   alt="">
              <span class="can fz14 ml10"
                    @click="showQuickReplay">快捷回复</span>
              <el-dialog title="快捷回复"
                         v-model="quickReplyStatus"
                         width="460px">
                <el-tabs v-model="activeName1"
                         class="demo-tabs">
                  <el-tab-pane :label="quickReply[0].name"
                               name="0">
                    <el-input class="broadcast_text"
                              resize="none"
                              v-model="quickReply[0].content"
                              type="textarea"
                              placeholder="" />
                  </el-tab-pane>
                  <el-tab-pane :label="quickReply[1].name"
                               name="1">
                    <el-input class="broadcast_text"
                              resize="none"
                              v-model="quickReply[1].content"
                              type="textarea"
                              placeholder="" />
                  </el-tab-pane>
                  <el-tab-pane :label="quickReply[2].name"
                               name="2">
                    <el-input class="broadcast_text"
                              resize="none"
                              v-model="quickReply[2].content"
                              type="textarea"
                              placeholder="" />
                  </el-tab-pane>
                  <el-tab-pane :label="quickReply[3].name"
                               name="3">
                    <el-input class="broadcast_text"
                              resize="none"
                              v-model="quickReply[3].content"
                              type="textarea"
                              placeholder="" />
                  </el-tab-pane>
                  <el-tab-pane :label="quickReply[4].name"
                               name="4">
                    <el-input class="broadcast_text"
                              resize="none"
                              v-model="quickReply[4].content"
                              type="textarea"
                              placeholder="" />
                  </el-tab-pane>
                </el-tabs>
                <template #footer>
                  <span class="dialog-footer">
                    <el-button type="primary"
                               @click="saveQuickReplay">保存</el-button>
                    <el-button type="primary"
                               @click="sendToInput1">发送到输入框</el-button>
                  </span>
                </template>
              </el-dialog>
            </div>
            <div class="private_chat_input_body">
              <textarea v-model="textarea"
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        @keyup.enter="privateChatSend"></textarea>
              <div class="flex at_c jc_e">
                <!-- <div @click="toBackVideo"
                     class="chat_btn">返回视频</div> -->
                <div @click="privateChatSend"
                     class="chat_btn">发送</div>
              </div>
            </div>
          </div>
        </div>
        <div class="private_chat_right">
          <div class="private_chat_right_title">
            <img src="@/assets/img/icon_history.png"
                 alt="">
            历史轨迹
          </div>
          <div class="fz14">
            <div class="bb p10 border-b">首次访问 {{memberInfo.first_time}}</div>
            <div class="bb p10 border-b">本次访问 {{memberInfo.last_time}}</div>
            <div class="bb p10 border-b">本月访问次数 {{memberInfo.visit_count}}次</div>
          </div>
          <div class="private_chat_right_title">
            <img src="@/assets/img/icon_infor.png"
                 alt="">
            客户信息
          </div>
          <div class="customer_info overflow_y">
            <div class="customer_cell">
              <div>昵称</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
            <div class="customer_cell">
              <div>IP</div>
              <div>{{memberInfo.ip}}</div>
            </div>
            <div class="customer_cell">
              <div>着陆页</div>
              <div>{{memberInfo.url}}</div>
            </div>
            <div class="customer_cell">
              <div>省市</div>
              <div>{{memberInfo.province}}{{memberInfo.city}}{{memberInfo.county}}</div>
            </div>
            <div class="customer_cell">
              <div>运营商</div>
              <div>{{memberInfo.lsp}}</div>
            </div>
            <div class="customer_cell">
              <div>关键词</div>
              <div>{{memberInfo.keywords}}</div>
            </div>
            <div class="customer_cell">
              <div>用户组</div>
              <div>{{memberInfo.type}}</div>
            </div>
            <div class="customer_cell">
              <div>观看视频</div>
              <div>{{memberInfo.video_status==1?'正常':'封禁'}}</div>
            </div>
            <div class="customer_cell">
              <div>发言</div>
              <div>{{memberInfo.chat_status==1?'正常':'封禁'}}</div>
            </div>
            <div class="customer_cell">
              <div>归属</div>
              <div>{{memberInfo.service_name}}</div>
            </div>
            <div class="customer_cell">
              <div>手机号</div>
              <div>{{memberInfo.mobile}}</div>
            </div>
            <div class="customer_cell">
              <div>备注</div>
              <div>{{memberInfo.bio}}</div>
            </div>
            <template v-if="memberInfo.is_firm_offer==1">
              <div class="cori_divider cori_divider_small">
                <div>实盘数据</div>
              </div>
              <div class="customer_cell">
                <div>姓名</div>
                <div>{{memberInfo.offerInfo.info.name}}</div>
              </div>
              <div class="customer_cell">
                <div>手机号</div>
                <div>{{memberInfo.offerInfo.info.phone}}</div>
              </div>

              <div class="customer_cell">
                <div>期末权益</div>
                <div>
                  <div class="mb15"
                       v-for="(item,index) in memberInfo.offerInfo.funds"
                       :key="index">
                    <div class="mb5">资产账号：{{item.asset}}</div>
                    <div class="mb5">开户时间：{{item.opentime}}</div>
                    <div class="mb5">天数：{{item.dormancy_days}}</div>
                    <div>权益：{{item.last_money}}</div>
                  </div>
                </div>
              </div>
            </template>
            <!-- 
            <div class="customer_cell">
              <div>休眠天数</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
            <div class="customer_cell">
              <div>开户时间</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
            <div class="customer_cell">
              <div>出金</div>
              <div>{{memberInfo.nickname}}</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="side_right">
      <!-- 客服列表 -->
      <div class="service_cell">
        <div class="plate_title jc_b">
          <div class="plate_title_name">客服列表</div>
          <span class="flex at_c jc_b line16 bb pr10 fz14">
            <span class="mr10">
              您好：
              <span style="color: #5389F5;">{{userName}}</span>
            </span>
            <span class="flex at_c can"
                  @click="setServiceStatusConfirm">
              当前状态：
              <span style="color: #5389F5;">{{userStatus?'已上线':'已下线'}}</span>
              <img class="icon_set"
                   src="@/assets/img/icon_set.png"
                   alt="">
            </span>
            <img @click="signOut"
                 class="icon_set can"
                 src="@/assets/img/icon_sign_out.png"
                 alt="">
          </span>
        </div>
        <div class="bb p10 h240 overflow_hidden"
             v-loading="serviceLoading">
          <div class="max_h240 overflow_y flex fw_w">
            <div class="staff_cell"
                 v-for="(item,index) in serviceList"
                 :key="index">
              {{item.service_name}}
              <div v-if="item.is_online"
                   class="staff_status_online"></div>
              <div v-else
                   class="staff_status_online staff_status_offline"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 公屏聊天 -->
      <!-- <div class="public_screen_chat"
           v-loading="chatListLoading">
        <div class="bb p10 screen_title">
          <div>
            <span class="fz14">在麦研究员</span>
            <span class="fz16 fb ml10">{{teacher_name}}</span>
          </div>
          <div class="flex at_c">
            <div class="flex at_c">
              <span class="fz12 mr5">发言审核已{{chat_status?'开启':'关闭'}}</span>
              <el-switch @click="changeChatStatus"
                         :value="chat_status"
                         active-color="#13ce66"
                         inactive-color="#ff4949"
                         :active-value="1"
                         :inactive-value="0">
              </el-switch>
            </div>
            <img @click="showPost"
                 class="can layui-icon-user"
                 src="@/assets/img/icon_post.png"
                 title="我的海报"
                 alt="">
            <img @click="doCopy(promotionLink)"
                 class="can layui-icon-user"
                 src="@/assets/img/layui-icon-user.png"
                 title="复制推广链接"
                 alt="">
            <img @click="checkSchedule"
                 class="can icon_class"
                 src="@/assets/img/icon_class.png"
                 title="查看课程表"
                 alt="">
            <el-dialog v-model="dialogVisible"
                       title="课程表"
                       width="36%"
                       draggable>
              <div class="class_group">
                <div class="class_cell"
                     v-for="(item,index) in courseList"
                     :key="index">
                  <div style="color: #919191;">
                    {{item.start_time + '-' + item.end_time}}
                    <img class="icon_living"
                         src="@/assets/img/icon_living.png"
                         alt="">
                  </div>
                  <el-popover placement="bottom"
                              :width="200"
                              trigger="hover"
                              :content="item.title">
                    <template #reference>
                      <div class="schedule_title hang1">{{item.title}}</div>
                    </template>
                  </el-popover>
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
        <div class="cori_divider">
          <div>公屏聊天</div>
        </div>
        <div class="chat_list overflow_y"
             id="chatList">
          <div class="chat_list_cell"
               v-for="(item,index) in chatList"
               :key="index">
            <div class="flex at_c jc_b">
              <div class="flex at_c">
                <div class="chat_label mr5">{{item.label}}</div>
                <el-popover placement="bottom"
                            trigger="click">
                  <div class="cori_popover">
                    <div>{{item.name}}</div>
                    <div @click="tellHim('@' + item.name + ':')">对TA说</div>
                    <div @click="ban('ip',item.ip)">封IP</div>
                    <div @click="ban('video','',item.user_id,item.type)">屏蔽此用户视频</div>
                    <div @click="ban('talk','',item.user_id,item.type)">禁止此用户发言</div>
                  </div>
                  <template #reference>
                    <div class="chat_nickname mr5 can">{{item.name}}</div>
                  </template>
                </el-popover>
                <div class="member_staff"
                     v-if="item.service_id">({{item.service_name}})</div>
              </div>
              <div class="chat_time">{{item.createtime_text}}</div>
            </div>
            <div class="chat_content">
              <div class="chat_content_div"
                   :class="{dummy_text: item.is_dummy}"
                   v-html="item.content"></div>
              <el-popconfirm v-if="item.status===0"
                             @confirm="examineSpeech(item.id)"
                             title="确认审核该发言吗？">
                <template #reference>
                  <div class="chat_examine">审核</div>
                </template>
              </el-popconfirm>
              <el-popconfirm @confirm="delSpeech(item.id)"
                             title="确认删除该发言吗？">
                <template #reference>
                  <div class="chat_del">删除</div>
                </template>
              </el-popconfirm>
            </div>
          </div>
        </div>
        <div class="pr">
          <emoji :id="emojiId"
                 v-if="publicEmojiStatus"
                 @changeInput="changeInput"></emoji>
          <div class="flex at_c bb p10">
            <img @click="fillqrcode"
                 class="wh22 ml5 can"
                 src="@/assets/img/layui-icon-cols.png"
                 alt="">
            <img @click.stop="changeEmojiStatus('public')"
                 class="wh22 ml5 can"
                 src="@/assets/img/icon_emoji.png"
                 alt="">
            <div @click="showBroadCast"
                 class="fz12 flex at_c ml5 can">
              <img class="wh22"
                   src="@/assets/img/icon_laba.png"
                   alt="">
              广播
              <el-dialog title="广播"
                         v-model="broadcastStatus"
                         width="460px">
                <el-tabs v-model="activeName"
                         class="demo-tabs"
                         @tab-click="handleClick">
                  <el-tab-pane label="广播一"
                               name="0">
                    <el-input class="broadcast_text"
                              resize="none"
                              v-model="broadcast[0].content"
                              type="textarea"
                              placeholder="" />
                  </el-tab-pane>
                  <el-tab-pane label="广播二"
                               name="1">
                    <el-input class="broadcast_text"
                              resize="none"
                              v-model="broadcast[1].content"
                              type="textarea"
                              placeholder="" />
                  </el-tab-pane>
                  <el-tab-pane label="广播三"
                               name="2">
                    <el-input class="broadcast_text"
                              resize="none"
                              v-model="broadcast[2].content"
                              type="textarea"
                              placeholder="" />
                  </el-tab-pane>
                  <el-tab-pane label="广播四"
                               name="3">
                    <el-input class="broadcast_text"
                              resize="none"
                              v-model="broadcast[3].content"
                              type="textarea"
                              placeholder="" />
                  </el-tab-pane>
                </el-tabs>
                <template #footer>
                  <span class="dialog-footer">
                    <el-button type="primary"
                               @click="saveBroadCast">保存</el-button>
                    <el-button type="primary"
                               @click="sendToInput">发送到输入框</el-button>
                  </span>
                </template>
              </el-dialog>
            </div>
            <div class="ml5 w80">
              <el-select @change="changeDummyId"
                         v-model="dummy_id"
                         class="m-2"
                         placeholder="请选择假人"
                         size="small">
                <el-option label="无"
                           :value="0" />
                <el-option v-for="item in dummyList"
                           :key="item.id"
                           :label="item.nickname"
                           :value="item.id" />
              </el-select>
            </div>
            <div class="ml5 w80">
              <el-select @change="changeDummyNum"
                         v-model="dummyNum"
                         class="m-2"
                         placeholder="请选择数量"
                         size="small">
                <el-option label="0人"
                           :value="0" />
                <el-option label="2人"
                           :value="2" />
                <el-option label="3人"
                           :value="3" />
              </el-select>
            </div>
            <div @click="clearScreen"
                 class="fz12 flex at_c ml5 can">
              <img class="wh22"
                   src="@/assets/img/icon_clear.png"
                   alt="">
              清屏
            </div>
          </div>
          <div class="flex at_c bb pl10 pr10 pb10">
            <div class="chat_input">
              <el-input v-model="input"
                        placeholder="请输入内容"
                        @keyup.enter="sendMessage"></el-input>
            </div>
            <div @click="sendMessage"
                 class="chat_btn">发送</div>
          </div>
        </div>
      </div> -->
    </div>

    <!-- ban选择时间 -->
    <el-dialog :title="banTitle"
               v-model="banShow"
               width="30%">
      <div class="flex at_c">
        <div>选择时长：</div>
        <el-select size="large"
                   v-model="expiration_time"
                   placeholder="请选择封禁时间">
          <el-option v-for="item in timeArr"
                     :key="item.expiration_time"
                     :label="item.name"
                     :value="item.expiration_time">
          </el-option>
        </el-select>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="banCancel">取 消</el-button>
          <el-button type="primary"
                     @click="banConfirm">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 海报 -->
    <el-dialog title="海报"
               v-model="postStatus">
      <div v-if="postList.length">
        <div class="flex jc_c">
          <img class="post_img"
               :src="postList[postIndex]"
               alt="">
        </div>
        <div class="mt20">
          <img @click="switchPost(index)"
               class="post_img_small"
               :class="{post_img_small_active: postIndex==index}"
               v-for="(item,index) in postList"
               :key="index"
               :src="item"
               alt="">
        </div>
      </div>
      <el-empty v-else
                description="还没有海报~"></el-empty>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="postStatus = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "../api/index";
import Emoji from "../components/emoji.vue";
import emojiList from "../utils/emoji";
import { useStore } from "vuex";
import socket from "../utils/socket";
var player;
export default {
  components: {
    Emoji,
  },
  data() {
    return {
      pageType: 1, //当前页面状态1视频直播2客服1v1聊天
      chatList: [], //公屏聊天消息列表
      chatListLoading: false, //公屏聊天消息列表加载状态
      room_id: 1,
      serviceList: [], //客服列表
      userStatus: 0, //当前用户在线状态
      userName: "", //  当前用户名称
      userId: null, // 当前用户id
      serviceLoading: false, // 客服列表加载状态
      input: "", // 当前用户待发送内容
      touristNewsStatus: 1, //1游客列表2历史列表
      touristNewsList: [], //游客列表
      user_list: [], //用户列表
      currentTalkMember: {
        id: null,
        type: null,
        nickname: "",
      }, //当前用户信息
      messageList: [], //当前聊天消息列表
      messageListLoading: false, // 当前聊天消息列表加载状态
      publicEmojiStatus: false, // 公屏聊天emoji输入状态
      privateEmojiStatus: false, // 私聊emoji输入状态
      emojiId: "", // 当前激活的emoji id
      textarea: "", // 客服私聊待发送内容
      videoUrl: "https://live.i-jiaoyi.com/live/ivce001.m3u8", // 视频直播的链接
      promotionLink: "这是测试链接",
      dialogVisible: false,
      phoneListLoading1: false, // 未拨打手机号列表加载状态--网销
      phoneList1: [], // 未拨打手机号列表--网销
      phoneListLoading2: false, // 未拨打手机号列表加载状态--电销
      phoneList2: [], // 未拨打手机号列表--电销
      courseList: [], // 课程表
      memberInfo: {
        offerInfo: {
          info: {},
          funds: [],
        },
      }, // 私聊人员相关信息
      chat_status: null, // 公屏聊天发言状态0不需要审核1需要审核
      timeJudgment: null, // 当前正在进行课程状态展示的定时器
      broadcast: [
        {
          name: "广播1",
          content: "",
        },
        {
          name: "广播2",
          content: "",
        },
        {
          name: "广播3",
          content: "",
        },
        {
          name: "广播4",
          content: "",
        },
      ], // 广播数组
      broadcastStatus: false, // 广播模块显示状态
      activeName: "0", // 当前激活的广播分页
      quickReply: [
        {
          name: "回复1",
          content: "",
        },
        {
          name: "回复2",
          content: "",
        },
        {
          name: "回复3",
          content: "",
        },
        {
          name: "回复4",
          content: "",
        },
        {
          name: "回复5",
          content: "",
        },
      ], // 快捷回复数组
      quickReplyStatus: false, // 快捷回复显示状态
      activeName1: "0", // 当前激活的快捷回复分页
      teacher_id: null, //当前在麦研究员id
      teacher_name: "", //当前在麦研究员name
      live_status: null, // 直播间播放状态1开播0停播
      stop_image: "", // 停播图
      dummy_id: null, // 选中的假人id
      dummyList: [], // 假人列表
      dummyNum: null, // 假人数量
      memberListLoading: false, // 游客&会员列表加载状态
      banShow: false, // ban操作的时间选择
      timeArr: [
        {
          expiration_time: 60 * 30,
          name: "30分钟",
        },
        {
          expiration_time: 60 * 60 * 24,
          name: "24小时",
        },
        {
          expiration_time: 60 * 60 * 24 * 15,
          name: "15天",
        },
        {
          expiration_time: 60 * 60 * 24 * 30,
          name: "1个月",
        },
        {
          expiration_time: -1,
          name: "永久",
        },
      ],
      expiration_time: 60 * 30,
      banTitle: "",
      banObj: {},
      postList: [], // 海报列表
      postStatus: false, // 海报显示状态
      postIndex: null,
    };
  },
  beforeUnmount() {
    if (this.live_status) {
      player.dispose();
    }
  },
  created() {
    // const store = useStore()
    // console.log('created',store.state)
    // if(!store.state.ws) {
    //   console.log('index开始连接socket')
    //   socket.initWebpack()
    // }
  },
  mounted() {
    // console.log('mounted')
    window.onmessageSocket = this.onmessage;
    //暂停
    // player.pause()
    this.getBaseInfo();
    this.getServiceIndex();
    this.getRoomChatList(Math.round(new Date() / 1000));
    this.getPhoneList1();
    this.getPhoneList2();
    this.getRoomConfig();
    this.getDummyList();
  },
  methods: {
    // 返回视频
    toBackVideo() {
      window.location.reload();
      // player = videojs("hls-video");
      // player.play();
      // this.pageType = 1;
    },
    onmessage(e) {
      //数据接收
      let that = this;
      const redata = JSON.parse(e.data);
      // console.log("index-onmessage", JSON.parse(e.data));
      var touristNewsList = this.touristNewsList;
      var user_list = this.user_list;
      var chatList = this.chatList;
      switch (redata.type) {
        //---------公屏聊天列表接收消息
        case "chat_room_accept":
          chatList.push({
            id: redata.chat_id,
            ip: redata.user_info.joinip,
            name: redata.user_info.nickname,
            content: this.emojiReplace(redata.content),
            status: redata.status,
            createtime_text: redata.createtime_text,
            service_id: redata.service.id,
            service_name: redata.service.nickname,
            label: redata.user_info.user_type,
            user_id: redata.user_info.id,
            type: redata.user_info.type,
            is_dummy: redata.user_info.is_dummy,
            is_service: redata.user_info.is_service,
            is_teacher: redata.user_info.is_teacher,
          });
          // console.log("socket接收公屏消息", redata);
          this.scoll();
          break;
        //---------客服上/下线
        case "service_join_status":
          this.serviceList = redata.content;
          break;
        //---------来访者打开聊天框
        case "user_join_service_group_accept":
          if (redata.user_info.type == 1) {
            // 会员
            for (const [index, item] of user_list.entries()) {
              if (
                item.type == redata.user_info.type &&
                item.id == redata.user_info.id
              ) {
                user_list.splice(index, 1);
              }
            }
            user_list.unshift({
              id: redata.user_info.id,
              avatar: redata.user_info.avatar,
              type: redata.user_info.type,
              user_type: redata.user_info.user_type,
              nickname: redata.user_info.nickname,
              time: redata.createtime_text,
              is_new: redata.is_new,
              is_new_message: redata.is_new_message,
            });
          } else {
            // 游客
            for (const [index, item] of touristNewsList.entries()) {
              if (
                item.type == redata.user_info.type &&
                item.id == redata.user_info.id
              ) {
                touristNewsList.splice(index, 1);
              }
            }
            touristNewsList.unshift({
              id: redata.user_info.id,
              avatar: redata.user_info.avatar,
              type: redata.user_info.type,
              user_type: redata.user_info.user_type,
              nickname: redata.user_info.nickname,
              time: redata.createtime_text,
              is_new: redata.is_new,
              is_new_message: redata.is_new_message,
            });
          }

          break;
        //---------来访者发送消息给客服
        case "chat_service_accept":
          if (redata.user_info.type == 1) {
            // 会员
            for (const [index, item] of user_list.entries()) {
              if (
                item.type == redata.user_info.type &&
                item.id == redata.user_info.id
              ) {
                user_list.splice(index, 1);
              }
            }
            user_list.unshift({
              id: redata.user_info.id,
              avatar: redata.user_info.avatar,
              type: redata.user_info.type,
              user_type: redata.user_info.user_type,
              nickname: redata.user_info.nickname,
              time: redata.createtime_text,
              is_new: redata.is_new,
              is_new_message: redata.is_new_message,
            });
          } else {
            // 游客
            for (const [index, item] of touristNewsList.entries()) {
              if (
                item.type == redata.user_info.type &&
                item.id == redata.user_info.id
              ) {
                touristNewsList.splice(index, 1);
              }
            }
            touristNewsList.unshift({
              id: redata.user_info.id,
              avatar: redata.user_info.avatar,
              type: redata.user_info.type,
              user_type: redata.user_info.user_type,
              nickname: redata.user_info.nickname,
              time: redata.createtime_text,
              is_new: redata.is_new,
              is_new_message: redata.is_new_message,
            });
          }

          var currentTalkMember = this.currentTalkMember,
            messageList = this.messageList;
          if (currentTalkMember.id == redata.user_info.id) {
            messageList.push({
              name: redata.user_info.nickname,
              avatar: redata.user_info.avatar,
              time: redata.createtime_text,
              content: this.emojiReplace(redata.content),
              direction: 2,
            });
            this.scoll1();
          }
          break;
        //---------会员游客断线(客服、研究员接收)
        case "user_drop_line":
          if (redata.content.type == 1) {
            // 会员
            for (const [index, item] of user_list.entries()) {
              if (item.id == redata.content.user_id) {
                user_list.splice(index, 1);
              }
            }
            that.user_list = user_list;
          } else {
            // 游客
            for (const [index, item] of touristNewsList.entries()) {
              if (item.id == redata.content.user_id) {
                touristNewsList.splice(index, 1);
              }
            }
            that.touristNewsList = touristNewsList;
          }
          break;
        //---------未拨打手机号列表推送
        case "distribution_tourist":
          if (redata.state == 1) {
            that.phoneList1.unshift({
              create_time: redata.data.create_time,
              id: redata.data.id,
              name: redata.data.name,
              phone: redata.data.phone,
              // pid: redata.data.pid,
              // remarks: redata.data.remarks,
              source: redata.data.source,
              source_title: redata.data.source_title,
              // state: redata.data.state,
            });
          } else {
            that.phoneList2.unshift({
              create_time: redata.data.create_time,
              id: redata.data.id,
              name: redata.data.name,
              phone: redata.data.phone,
              // pid: redata.data.pid,
              // remarks: redata.data.remarks,
              source: redata.data.source,
              source_title: redata.data.source_title,
              // state: redata.data.state,
            });
          }
          break;
        //---------直播间发言审核变动
        case "room_chat_status":
          this.$notify({
            title: "提示",
            message: "发言审核状态已被修改",
            duration: 3000,
          });
          this.chat_status = redata.content.chat_status;
          break;
        //---------直播间删除某条信息
        case "room_chat_delete":
          // console.log("redata", redata.content.id);
          for (const [index, item] of chatList.entries()) {
            if (item.id == redata.content.id) {
              chatList.splice(index, 1);
            }
          }
          this.chatList = chatList;
          break;
        //---------直播间变更状态
        case "live_status":
          var live_status = redata.content.live_status;
          setTimeout(() => {
            if (live_status) {
              player = videojs("hls-video");
              player.play();
            } else {
              player.pause();
            }
          }, 100);
          this.live_status = live_status;
          this.stop_image = redata.content.stop_image;
          break;
        //---------直播间消息审核成功(接收)
        case "room_chat_apply":
          for (const item of chatList) {
            if (item.id == redata.content.id) {
              item.status = 1;
            }
          }
          this.chatList = chatList;
          break;
        //---------告诉管理员这个用户不是新的了
        case "hide_is_new":
          if (redata.content.user_type == 1) {
            // 会员
            for (const item of user_list) {
              if (item.id == redata.content.user_id) {
                item.is_new = false;
              }
            }
            that.user_list = user_list;
          } else {
            // 游客
            for (const item of touristNewsList) {
              if (item.id == redata.content.user_id) {
                item.is_new = false;
              }
            }
            that.touristNewsList = touristNewsList;
          }
          break;
        //---------告诉管理员这个用户的消息不是未读了
        case "hide_is_new_message":
          if (redata.content.user_type == 1) {
            // 会员
            for (const item of user_list) {
              if (item.id == redata.content.user_id) {
                item.is_new_message = false;
              }
            }
            that.user_list = user_list;
          } else {
            // 游客
            for (const item of touristNewsList) {
              if (item.id == redata.content.user_id) {
                item.is_new_message = false;
              }
            }
            that.touristNewsList = touristNewsList;
          }
          break;
        //---------websocket出现错误进行提示
        case "error":
          this.$message.error(redata.content);
          break;
      }
      // return false
    },
    // 获取假人列表
    async getDummyList() {
      const res = await Service.getDummyList({
        is_paginate: 0,
        is_dummy: 1,
      });
      // console.log("获取假人列表", res);
      this.dummyList = res.data;
    },
    // 专属二维码
    fillqrcode() {
      this.input += "【专属二维码】";
    },
    // 广播发送到输入框
    sendToInput() {
      this.input = this.broadcast[this.activeName * 1].content;
      this.broadcastStatus = false;
    },
    // 修改广播
    async saveBroadCast() {
      const res = await Service.setBroadcast({
        broadcast: this.broadcast,
      });
      // console.log("配置广播", res);
      this.$message.success(res.msg);
      this.broadcastStatus = false;
    },
    //切换广播分页
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    //显示广播模块
    showBroadCast() {
      this.getServiceIndex();
      this.broadcastStatus = true;
    },
    //显示快捷回复模块
    showQuickReplay() {
      this.getServiceIndex();
      this.quickReplyStatus = true;
    },
    // 修改快捷回复
    async saveQuickReplay() {
      const res = await Service.setQuickReply({
        quick_reply: this.quickReply,
      });
      this.$message.success(res.msg);
      this.quickReplyStatus = false;
    },
    // 快捷回复发送到输入框
    sendToInput1() {
      this.textarea = this.quickReply[this.activeName1 * 1].content;
      this.quickReplyStatus = false;
    },
    //对TA说
    tellHim(e) {
      this.input = e + this.input;
    },
    ban(action, ip, user_id, type) {
      this.banShow = true;
      switch (action) {
        case "ip":
          this.banTitle = "确认对该用户进行封禁IP吗？";
          // this.banIP(ip);
          break;
        case "video":
          this.banTitle = "确认对该用户进行封禁视频吗？";
          // this.banVideo(user_id, type);
          break;
        case "talk":
          this.banTitle = "确认对该用户进行封禁发言吗？";
          // this.banTalk(user_id, type);
          break;
      }
      this.banObj = {
        action,
        ip,
        user_id,
        type,
      };
    },
    banCancel() {
      this.banShow = false;
      this.banTitle = "";
      this.banObj = {};
    },
    banConfirm() {
      var banObj = this.banObj;
      switch (banObj.action) {
        case "ip":
          this.banIP(banObj.ip);
          break;
        case "video":
          this.banVideo(banObj.user_id, banObj.type);
          break;
        case "talk":
          this.banTalk(banObj.user_id, banObj.type);
          break;
      }
      this.banCancel();
    },
    //封IP
    async banIP(ip) {
      const res = await Service.banIp({
        ip,
        expiration_time: this.expiration_time,
      });
      // console.log("封IP", res);
      this.$message.success(res.msg);
    },
    //屏蔽此用户视频
    async banVideo(user_id, type) {
      const res = await Service.changeVideoStatus({
        user_id,
        type,
        video_status: 0,
        expiration_time: this.expiration_time,
      });
      // console.log("屏蔽此用户视频", res);
      this.$message.success(res.msg);
    },
    //禁止此用户发言
    async banTalk(user_id, type) {
      const res = await Service.changeUserChatStatus({
        user_id,
        type,
        chat_status: 0,
        expiration_time: this.expiration_time,
      });
      // console.log("禁止此用户发言", res);
      this.$message.success(res.msg);
    },
    //审核发言
    async examineSpeech(id) {
      const res = await Service.applyChat({
        id,
      });
      // console.log("审核发言", res);
    },
    //删除公屏发言
    async delSpeech(id) {
      const res = await Service.delChat({
        id,
      });
      // console.log("删除公屏发言", res);
    },
    timing() {
      var d = new Date();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();

      // console.log("hour", hour);
      // console.log("min", min);
    },
    //修改发言审核开关
    async changeChatStatus() {
      const res = await Service.changeChatStatus({
        room_id: this.room_id,
        chat_status: this.chat_status == 1 ? 0 : 1,
      });
      this.$message.success(res.msg);
    },
    //获取课表
    async getRoomConfig() {
      const res = await Service.roomConfigInfo({
        room_id: this.room_id,
      });
      var courseList = res.data.room_setting;
      for (const item of courseList) {
        item.going = false;
      }
      this.courseList = courseList;
      // console.log("getRoomConfig", res);
    },
    //获取手机号列表--网销
    async getPhoneList1() {
      this.phoneListLoading1 = true;
      const res = await Service.phoneList({
        type: 1,
      });
      // console.log("getPhoneList", res);
      this.phoneList1 = res.data.data.data;
      this.phoneListLoading1 = false;
    },
    //获取手机号列表--电销
    async getPhoneList2() {
      this.phoneListLoading2 = true;
      const res = await Service.phoneList({
        type: 2,
      });
      // console.log("getPhoneList", res);
      this.phoneList2 = res.data.data.data;
      this.phoneListLoading2 = false;
    },
    //未拨打手机号列表操作
    async phoneStatus(id, content, state, type) {
      let that = this;
      const res = await Service.phoneStatus({
        id,
        content,
        state,
        type,
      });
      if (type == 1) {
        var phoneList1 = that.phoneList1;
        for (const index in phoneList1) {
          if (phoneList1[index].id == id) {
            phoneList1.splice(index, 1);
          }
        }
        that.$message.success("操作成功");
      } else {
        var phoneList2 = that.phoneList2;
        for (const index in phoneList2) {
          if (phoneList2[index].id == id) {
            phoneList2.splice(index, 1);
          }
        }
        that.$message.success("操作成功");
      }
    },
    showBox(name, phone, id, type) {
      let that = this;
      that
        .$prompt("客户信息：" + name + " " + phone, "请输入反馈内容", {
          confirmButtonText: "已拨通",
          cancelButtonText: "未拨通",
          closeOnPressEscape: false,
          closeOnClickModal: false,
          distinguishCancelAndClose: true,
        })
        .then(({ value }) => {
          // 确认
          that
            .$confirm("是否已添加联系方式电话？", "信息", {
              confirmButtonText: "已添加",
              cancelButtonText: "未添加",
              type: "warning",
              closeOnPressEscape: false,
              closeOnClickModal: false,
              distinguishCancelAndClose: true,
            })
            .then(() => {
              that.phoneStatus(id, value, 6, type);
            })
            .catch((action) => {
              if (action === "cancel") {
                //未添加
                that.phoneStatus(id, value, 4, type);
              } else {
                //取消操作
              }
            });
        })
        .catch((action) => {
          if (action === "cancel") {
            //未拨通
            that.phoneStatus(id, "", 2, type);
          } else {
            //取消操作
          }
        });
    },
    //公屏聊天滚动
    scoll() {
      $("#chatList").animate(
        {
          scrollTop: "99999px",
        },
        1200
      );
    },
    //客服私聊滚动
    scoll1() {
      $("#privateChat").animate(
        {
          scrollTop: "99999px",
        },
        1200
      );
    },
    // 获取基础信息
    async getBaseInfo() {
      this.serviceLoading = true;
      this.memberListLoading = true;
      const res = await Service.initInfo();
      // console.log("getBaseInfo", res);
      var serviceList = res.data.serviceList,
        touristNewsList = res.data.userGroup.visitor_list,
        user_list = res.data.userGroup.user_list;
      this.serviceList = serviceList;
      this.touristNewsList = touristNewsList;
      this.user_list = user_list;
      this.room_id = res.data.roomInfo.id;
      this.chat_status = res.data.roomInfo.chat_status;
      this.teacher_id = res.data.roomInfo.teacher.id;
      this.teacher_name = res.data.roomInfo.teacher.teacher_name;
      this.live_status = res.data.roomInfo.live_status;
      this.stop_image = res.data.roomInfo.stop_image;
      this.serviceLoading = false;
      this.memberListLoading = false;
      setTimeout(() => {
        if (res.data.roomInfo.live_status) {
          player = videojs("hls-video");
          player.play();
        }
      }, 100);
    },
    changeTouristNewsStatus(e) {
      this.touristNewsStatus = e;
    },
    //设置客服状态
    setServiceStatusConfirm() {
      this.$confirm(
        "确认修改状态为" + (this.userStatus == 0 ? "上线" : "下线") + "?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.setServiceStatus();
      });
    },
    async setServiceStatus() {
      this.serviceLoading = true;
      const res = await Service.setServiceStatus({
        service_status: this.userStatus == 0 ? 1 : 0,
      });
      this.getServiceIndex();
      // console.log("setServiceStatus", res);
      this.$message({
        type: "success",
        message: "操作成功!",
      });
    },
    //获取客服信息
    async getServiceIndex() {
      const res = await Service.getServiceIndex();
      // console.log("getServiceIndex", res);
      this.userStatus = res.data.userinfo.service_status;
      this.userName = res.data.userinfo.nickname;
      this.userId = res.data.userinfo.user_id;
      // 推广链接
      this.promotionLink = res.data.userinfo.service_share_url;
      // 广播
      if (res.data.userinfo.broadcast) {
        this.broadcast = res.data.userinfo.broadcast;
      }
      // 快捷回复
      if (res.data.userinfo.quick_reply) {
        this.quickReply = res.data.userinfo.quick_reply;
      }
      this.serviceLoading = false;
    },
    //获取公屏聊天历史消息
    async getRoomChatList(createtime) {
      this.chatListLoading = true;
      const res = await Service.roomChatList({
        room_id: this.room_id,
        createtime: createtime,
      });
      // console.log("getRoomChatList", res);
      var chatList = this.chatList;
      for (const item of res.data.list) {
        chatList.unshift({
          id: item.id,
          ip: item.user.joinip,
          name: item.user.nickname,
          content: this.emojiReplace(item.content),
          status: item.status,
          createtime_text: item.createtime_text,
          service_id: item.service.id,
          service_name: item.service.service_name,
          label: item.user.user_type,
          user_id: item.user.id,
          type: item.user.type,
          is_dummy: item.user.is_dummy,
          is_service: item.user.is_service,
          is_teacher: item.user.is_teacher,
        });
      }
      this.chatList = chatList;

      this.chatListLoading = false;
      this.scoll();
    },
    //公屏聊天发送消息
    sendMessage() {
      let that = this;
      var dummyNum = that.dummyNum,
        dummy_id = that.dummy_id,
        dummyList = that.dummyList,
        len = dummyList.length;
      if (!that.input) {
        that.$message.error("不能发送空消息");
        return false;
      }
      if (dummyNum) {
        for (let index = 0; index < dummyNum; index++) {
          setTimeout(() => {
            that.$store.state.ws.send(
              JSON.stringify({
                type: "chat_room_send",
                room_id: that.room_id,
                content: that.input,
                dummy_id: dummyList[Math.floor(Math.random() * len)].id,
                config: {
                  platform: "serviceWeb",
                },
              })
            );
          }, Math.floor(Math.random() * 3) * 1000);
        }
        return false;
      }
      that.$store.state.ws.send(
        JSON.stringify({
          type: "chat_room_send",
          room_id: that.room_id,
          content: that.input,
          dummy_id,
          config: {
            platform: "serviceWeb",
          },
        })
      );
      that.input = "";
      that.publicEmojiStatus = false;
    },
    // 变更假人
    changeDummyId(e) {
      if (e) {
        this.dummyNum = null;
      }
    },
    // 变更假人数量
    changeDummyNum(e) {
      if (e) {
        this.dummy_id = null;
      }
    },
    //公屏表情输入
    changeInput(e) {
      this.input += e;
    },
    //私聊表情输入
    changeTextarea(e) {
      this.textarea += e;
    },
    emojiStatusHide() {
      this.privateEmojiStatus = false;
      this.publicEmojiStatus = false;
    },
    //emoji选择列表展示状态更改
    changeEmojiStatus(e) {
      if (e == "public") {
        this.publicEmojiStatus = !this.publicEmojiStatus;
        this.privateEmojiStatus = false;
        this.emojiId = "public";
      } else if (e == "private") {
        this.publicEmojiStatus = false;
        this.privateEmojiStatus = !this.privateEmojiStatus;
        this.emojiId = "private";
      }
    },
    //选择私聊人员获取聊天记录
    async privateChat(type, id, nickname, cate) {
      // cate 1：touristNewsList；2：user_list
      // 查看时销毁播放器，避免长时间后占用内存导致卡顿
      if (this.live_status) {
        player.dispose();
      }
      let that = this;
      that.pageType = 2;
      that.messageListLoading = true;
      that.messageList = [];
      that.currentTalkMember = {
        id,
        type,
        nickname,
      };
      var touristNewsList = that.touristNewsList,
        user_list = that.user_list;
      const res = await Service.getChatList({
        type,
        uid: id,
        // 暂时取消，用户电脑时间不准的情况下，会有偏差
        // createtime: Math.round(new Date() / 1000),
      });
      // console.log("privateChat", res);
      var list = res.data.list,
        messageList = that.messageList;
      for (const item of list) {
        messageList.unshift({
          name: item.user.nickname,
          avatar: item.user.avatar,
          time: item.createtime_text,
          content: that.emojiReplace(item.content),
          direction: item.service_id == item.to_user_id ? 2 : 1,
        });
      }
      if (cate == 1) {
        for (const item of touristNewsList) {
          if (item.id == id) {
            item.is_new = false;
            item.is_new_message = false;
          }
        }
      } else {
        for (const item of user_list) {
          if (item.id == id) {
            item.is_new = false;
            item.is_new_message = false;
          }
        }
      }
      that.messageListLoading = false;
      that.getUserInfo(type, id);
      that.scoll1();
    },
    //获取私聊人员相关信息
    async getUserInfo(type, id) {
      this.messageListLoading = true;
      const res = await Service.userInfo({
        type,
        id,
      });
      // console.log("getUserInfo", res);
      this.messageListLoading = false;
      this.memberInfo = res.data;
    },
    //私聊发送
    privateChatSend() {
      if (!this.textarea) {
        this.$message.error("不能发送空消息");
        return false;
      }
      this.$store.state.ws.send(
        JSON.stringify({
          type: "chat_service_send",
          uuid: "",
          to_user_id: this.currentTalkMember.id,
          service_id: this.userId,
          content: this.textarea,
          user_type: this.currentTalkMember.type,
          config: {
            platform: "serviceWeb",
          },
        })
      );
      this.messageList.push({
        name: this.userName,
        avatar: "",
        time: this.getSendTime(),
        content: this.emojiReplace(this.textarea),
        direction: 1,
      });
      this.textarea = "";
      this.privateEmojiStatus = false;
      var touristNewsList = this.touristNewsList,
        user_list = this.user_list;
      for (const item of touristNewsList) {
        if (
          item.type == this.currentTalkMember.type &&
          item.id == this.currentTalkMember.id
        ) {
          item.is_new_message = false;
        }
      }
      for (const item of user_list) {
        if (
          item.type == this.currentTalkMember.type &&
          item.id == this.currentTalkMember.id
        ) {
          item.is_new_message = false;
        }
      }
      this.scoll1();
    },
    // 我的海报
    async showPost() {
      const res = await Service.getUserPoster({
        type: "pc",
      });
      this.postList = res.data.poster_url;
      this.postStatus = true;
      this.postIndex = 0;
    },
    switchPost(index) {
      this.postIndex = index;
    },
    //复制推广链接
    doCopy(val) {
      this.$copyText(val).then(
        (e) => {
          this.$message.success("复制成功");
        },
        (e) => {
          this.$message.error("复制失败");
        }
      );
    },
    //退出
    signOut() {
      this.$confirm("即将退出, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        localStorage.setItem("vce-token-service", "");
        localStorage.setItem("is_service_admin", "");
        this.$router.push("/login");
      });
    },
    checkSchedule() {
      // this.$emit("showDialog");
      this.dialogVisible = true;
    },
    //清屏
    clearScreen() {
      this.chatList = [];
    },
    //替换内容中的emoji字符
    emojiReplace(val) {
      // console.log("emojiReplace", val);
      for (const item of emojiList) {
        val = val.replace(
          new RegExp(item.regexp, "g"),
          '<img style="width: 18px;height: 18px;" src="' + item.url + '" />'
        );
      }
      return val;
    },
    getSendTime() {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date = year + "-" + month + "-" + date;
      var cori_time = hour + ":" + min + ":" + sec;
      this.currentDate = cori_date;
      this.currentTime = cori_time;

      return cori_date + " " + cori_time;
    },
  },
};
</script>

<style scoped>
.side_left {
  /* width: 18%; */
  width: 334px;
  height: 100vh;
}

.video_center,
.private_chat {
  /* width: 58%; */
  width: calc(100% - 754px);
  height: 100vh;
  border-left: 1px solid #ebeced;
  border-right: 1px solid #ebeced;
  box-sizing: border-box;
}

.video_center {
  display: flex;
  align-items: center;
}

.side_right {
  /* width: 24%; */
  width: 420px;
  height: 100vh;
}

.left_one {
  /* height: 580px; */
  /* height: 55%; */
  height: 100%;
}

.left_two {
  /* height: calc(100% - 580px); */
  height: 45%;
}

.list_head {
  display: flex;
  background-color: #f8fcff;
  border-bottom: 1px solid #ebeced;
}

.list_head_cell {
  flex: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.list_head_cell img {
  width: 24px;
  height: 24px;
  margin: 10px 0;
}

.list_head_cell_active {
  position: relative;
}

.list_head_cell_active::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  border: 1px solid #0092fd;
}

.offline_un {
  margin-right: 20px !important;
}

.tourist_news_list {
  height: calc(100% - 45px);
}

.overflow_y {
  overflow-y: auto;
}

.overflow_y::-webkit-scrollbar {
  display: block;
  width: 4px;
}

.overflow_y::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #aaa;
  background: rgba(0, 0, 0, 0.2);
}

.overflow_y::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #aaa;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.tourist_news_cell {
  height: 66px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeced;
  cursor: pointer;
  position: relative;
}

.tourist_avatar_cell {
  width: 40px;
  height: 40px;
  margin-right: 6px;
}

.tourist_avatar_img {
  width: 100%;
  height: 100%;
}

.tourist_identity {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  background-color: #4571e7;
  box-sizing: border-box;
  padding: 3px 4px;
  display: inline-flex;
  margin-right: 4px;
}

.tourist_time {
  color: #8691a3;
  font-size: 12px;
  line-height: 12px;
}

.h40 {
  height: 40px;
}

.icon_new {
  width: 18px;
  height: 18px;
}

.tourist {
  background-image: url("../assets/img/tourist_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.tourist_news_tips {
  position: absolute;
  right: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ff3f30;
}

.div_phone_list {
  height: calc(100% - 45px);
}

.phone_list {
  height: 100%;
  flex: 1;
}

.phone_list:first-child {
  border-right: 1px solid #eee;
}

.phone_cell {
  height: 80px;
  border-bottom: 1px solid #ebeced;
  cursor: pointer;
  position: relative;
}

.iphoneuser_img {
  width: 40px;
  height: 40px;
  margin-right: 6px;
}

.phone_info {
  width: calc(100% - 46px);
}

.phone_time {
  color: #858fa2;
  font-size: 12px;
}

.phone_type {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  background-color: #019788;
  box-sizing: border-box;
  padding: 3px 4px;
  display: inline-flex;
  margin-right: 4px;
}

.phone_copy {
  width: 14px;
  height: 14px;
  margin-left: 5px;
}

.plate_title {
  height: 45px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeced;
  box-sizing: border-box;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.icon_set {
  width: 24px;
  height: 24px;
}

.line16 {
  line-height: 16px;
}

.service_cell {
  background-color: #f7f8fc;
}

.h240 {
  height: 140px;
}

.max_h240 {
  max-height: 140px;
}

.staff_cell {
  background-color: #fff;
  font-size: 14px;
  line-height: 14px;
  border-radius: 5px;
  border: 1px solid #ebeced;
  box-sizing: border-box;
  width: 66px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 10px 0;
  position: relative;
}

.staff_status_online,
.staff_status_offline {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 5px;
}

.staff_status_online {
  background: #20d79d;
  box-shadow: 0px 1px 4px rgba(11, 182, 120, 0.7);
}

.staff_status_offline {
  filter: grayscale(100%);
  filter: gray;
}

.public_screen_chat {
  height: calc(100% - 185px);
  background-color: #f7f8fc;
}

.screen_title {
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.layui-icon-user,
.icon_class {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.cori_divider > div {
  font-size: 12px;
  font-weight: 400;
  color: #a3adbf;
  position: relative;
  text-align: center;
  margin: 10px 0;
}

.cori_divider > div::before {
  content: "";
  display: block;
  width: 30%;
  position: absolute;
  left: 45px;
  top: 10px;
  height: 1px;
  background: linear-gradient(90deg, #ffffff 0%, #e6e9f0 49%, #ffffff 100%);
}

.cori_divider > div::after {
  content: "";
  display: block;
  width: 30%;
  position: absolute;
  right: 45px;
  top: 10px;
  height: 1px;
  background: linear-gradient(90deg, #ffffff 0%, #e6e9f0 49%, #ffffff 100%);
}

.chat_list {
  background-color: #fff;
  box-sizing: border-box;
  padding: 10px;
  height: calc(100% - 174px);
}

.chat_list_cell:last-child {
  padding-bottom: 200px;
}

.chat_label {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  background-color: #4571e7;
  box-sizing: border-box;
  padding: 3px 4px;
  display: inline-flex;
  margin-right: 4px;
}

.chat_nickname {
  font-size: 14px;
  color: #4571e7;
}

.member_staff {
  color: #999999;
  font-size: 12px;
}

.chat_time {
  color: #999999;
  font-size: 12px;
}

.chat_content {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  box-sizing: border-box;
  padding: 5px 80px 5px 0;
  margin-bottom: 15px;
  position: relative;
}

.chat_content_div >>> img {
  width: 200px;
}

.chat_examine {
  color: #3550f6;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 5px;
}

.chat_del {
  color: #3550f6;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 5px;
}

.wh22 {
  width: 22px;
  height: 22px;
}

.chat_btn {
  font-size: 12px;
  line-height: 12px;
  background-color: #5188f4;
  color: #fff;
  width: 60px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}

.chat_input {
  width: calc(100% - 70px);
}

.private_chat_head {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 15px;
  background-color: #f8fcff;
  border-bottom: 1px solid #ebeced;
}

.icon_operation {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.private_chat_body {
  display: flex;
  height: calc(100% - 45px);
}

.private_chat_left {
  width: calc(100% - 250px);
}

.private_chat_right {
  width: 250px;
  height: 100%;
  box-sizing: border-box;
  border-left: 1px solid #ebeced;
  background-color: #f7f8fc;
}

.private_chat_list {
  box-sizing: border-box;
  padding: 20px;
  height: calc(100% - 190px);
}

.private_chat_bottom {
  width: 100%;
  height: 190px;
  background-color: #f7f8fa;
}

.private_chat_bottom_more {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
}

.private_chat_bottom_more_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.private_chat_input_body {
  height: calc(100% - 40px);
  box-sizing: border-box;
  padding: 0 10px;
}

.private_chat_input_body textarea {
  width: 100%;
  box-sizing: border-box;
  height: calc(100% - 40px);
  border: 1px solid #cccccc;
  resize: none;
  outline: none;
  padding: 10px;
}

.private_chat_right_title {
  height: 45px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeced;
  box-sizing: border-box;
  padding: 0 10px;
  line-height: 14px;
  font-size: 14px;
}

.private_chat_right_title img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.border-b {
  border-bottom: 1px solid #ebeced;
}

.customer_info {
  box-sizing: border-box;
  padding: 10px;
  height: calc(100% - 210px);
}

.customer_cell {
  display: flex;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 14px;
  color: #94a3c3;
}

.customer_cell > div:first-child {
  width: 60px;
}

.customer_cell > div:last-child {
  width: calc(100% - 60px);
}

.cori_divider_small > div {
  color: #5288f5;
}

.cori_divider_small > div::before {
  width: 30%;
  left: 15px;
}

.cori_divider_small > div::after {
  width: 30%;
  right: 15px;
}

.cori_chat_box_list_cell {
  display: flex;
}

.cori_chat_box_list_cell_avatar {
  width: 32px;
  height: 32px;
  margin-right: 6px;
}

.cori_chat_box_list_cell_info {
  width: calc(100% - 38px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mr6 {
  margin-right: 6px;
}

.cori_chat_box_list_cell_content {
  margin: 6px 0 20px;
  font-size: 14px;
  max-width: 65%;
  background-color: #f7f8fc;
  box-sizing: border-box;
  padding: 6px 10px;
  border-radius: 5px;
}

.color_huiyuan {
  background-color: #4571e7;
}

.color_youke {
  background-color: #4571e7;
}

.color_jiangshi {
  background-color: #ff3f30;
}

.color_guanli {
  background-color: #ffbc01;
}

.color_kefu {
  background-color: #ffbc01;
}

.hls-video-dimensions {
  width: 100%;
}

.class_group {
  display: flex;
  flex-wrap: wrap;
}

.class_cell {
  box-sizing: border-box;
  padding: 10px;
  margin: 0 14px 14px 0;
  box-shadow: #dfdfdf 0px 0px 2px 1px;
}

.icon_living {
  width: 12px;
  height: 14px;
}

.schedule_title {
  font-weight: bold;
  width: 100px;
}

/deep/.el-dialog__body {
  padding: 10px 22px 30px;
}

.cori_popover > div:first-child {
  font-weight: bold;
}

.cori_popover > div:not(:first-child) {
  color: #4988ef;
  margin-top: 10px;
  cursor: pointer;
}

.broadcast_text {
  height: 200px;
}

.broadcast_text /deep/.el-textarea__inner {
  height: 100%;
}

.w80 {
  width: 100px;
}

.w40 {
  width: 60px;
}

.icon_nav_hq {
  width: 150px;
}

.h100 {
  height: 100%;
}

.overflow_hidden {
  overflow: hidden;
}

.dummy_text {
  color: #ff7220;
}

.post_img {
  width: 280px;
}

.post_img_small {
  width: 70px;
  height: 70px;
  object-fit: contain;
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid transparent;
}

.post_img_small_active {
  border: 2px solid #5188f4;
}
</style>